<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div>hello ges</div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  data() {
    return this.initialState();
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
