<template>
  <div class="flex flex-col gap-6 mt-3">
    <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
      <vs-button @click="navigateToForm">Create Debit Note</vs-button>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      v-if="showData"
    >
      <template slot="thead">
        <vs-th style="width: 15%">
          <vs-checkbox v-model="checkedAll"> Check All </vs-checkbox>
        </vs-th>
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
          <vs-td
            ><vs-checkbox
              @click="addChecked(tr.supplier_returns.id, indextr)"
              :checked="check(tr.supplier_returns.id)"
            ></vs-checkbox
          ></vs-td>
          <vs-td>{{ tr.supplier_returns.code }}</vs-td>
          <vs-td>
            Code : {{ tr.supplier_returns.supplier_code }} <br />
            Name : {{ tr.supplier_returns.supplier_name }}
          </vs-td>
          <vs-td>{{ tr.supplier_returns.grr_code }}</vs-td>
          <vs-td>{{ tr.supplier_returns.ref_status }}</vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>

  <!-- form -->
  <!-- <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
       
          <detail
            @close="handleClose"
            :dn_id="dn_id"
            :dn_code="dn_code"
            :cn_number="cn_number"
            :cn_date="cn_date"
            :tr_number="tr_number"
            :tr_date="tr_date"
            :remaining_dn_value="remaining_dn_value"
            :notes="notes"
          >
          </detail>
        </vs-row>
      </div>
    </transition> -->
</template>
<script>
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  props: {
    supplierCodes: {
      type: Array,
      default: () => [],
    },
    srNumbers: {
      type: Array,
      default: () => [],
    },
    supplierCodeOptions: {
      type: Array,
      default: () => [],
    },
    SrNumberOptions: {
      type: Array,
      default: () => [],
    },
    showData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        // {
        //   text: "Action",
        // },
        {
          text: "SR Number",
        },
        {
          text: "Supplier",
        },
        {
          text: "GRR Code",
        },
        {
          text: "SR Type",
        },
      ],
      responseData: {},
      supplierReturns: [],
      checked: [],
      checkedID: [],
      checkedAll: false,
    };
  },
  methods: {
    reloadData(params) {
      params = {
        ...params,
        pr_status: 11,
        sr_status: 8,
      };

      if (this.supplierCodes.some((v) => v.supplier_code === "All")) {
        params.scds = this.supplierCodeOptions.map((v) => v.supplier_code);
      } else {
        params.scds = this.supplierCodes.map((v) => v.supplier_code);
      }

      if (this.srNumbers.some((v) => v.code === "All")) {
        params.srns = this.SrNumberOptions.map((v) => v.code);
      } else {
        params.srns = this.srNumbers.map((v) => v.code);
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/supplier-return", { params })
        .then((res) => {
          if (res.code < 299) {
            this.$vs.loading.close();
            this.responseData = res.data;
            this.supplierReturns = res.data.records ? res.data.records : [];
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
    },
    addChecked(val, indexTr) {
      if (this.checkedID.includes(val)) {
        const index = this.checkedID.indexOf(val);
        this.checked.splice(index, 1);
        this.checkedID.splice(index, 1);
        console.log("remove");
      } else {
        this.checked.push(this.responseData.records[indexTr]);
        this.checkedID.push(val);
        console.log("add");
      }
      console.log(this.checked);
    },
    check(val) {
      if (this.checked.length === 0 && this.checkedID.length === 0) {
        return false;
      }
      return this.checkedID.includes(val);
    },
    validateDistributor() {
      return this.checked.every(
        (v) =>
          (v.supplier_returns.supplier_id ===
            this.checked[0].supplier_returns.supplier_id ||
            v.supplier_returns.supplier_code ===
              this.checked[0].supplier_returns.supplier_code ||
            v.supplier_returns.supplier_name ===
              this.checked[0].supplier_returns.supplier_name) &&
          v.supplier_returns.ref_status ===
            this.checked[0].supplier_returns.ref_status
      );
    },
    navigateToForm() {
      if (this.checked.length === 0) {
        this.$vs.notify({
          title: "Debit Note Creation",
          text: "Please select at least one SR Number to create Debit Note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      if (!this.validateDistributor()) {
        this.$vs.notify({
          title: "Debit Note Creation",
          text: "Supplier and SR Type must be same",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      console.log(this.checked);
      const temp = this.checked.map((v) => v.supplier_returns.id);
      temp.sort((a, b) => b - a);
      const queryStr = JSON.stringify(temp);
      console.log(queryStr, "diencode ges");
      this.$router.push({
        name: "dn-with-or-without-po-ref-form",
        query: {
          sr_ids: btoa(queryStr),
        },
      });
    },
  },
  mounted() {},
  watch: {
    checkedAll(val) {
      if (val) {
        this.checked = [];
        this.checked = this.responseData.records;
        this.checkedID = this.responseData.records.map(
          (v) => v.supplier_returns.id
        );
      } else {
        this.checked = [];
        this.checkedID = [];
      }
    },
    supplierCodes() {
      this.reloadData(this.params);
    },
    srNumbers() {
      this.reloadData(this.params);
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
      .core-leave-to
  
      /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
