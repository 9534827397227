<template>
  <div :class="displayClass">
    <span v-for="(number, i) in srArray" :key="i">
      {{ number }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    srNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      srArray: [],
      displayClass: "",
    };
  },
  // computed: {
  //   displayClass() {
  //     return this.srArray.length > 1 ? "flex flex-col gap-4" : "flex flex-col";
  //   },
  // },
  mounted() {
    if (this.srNumber.includes(";")) {
      this.srArray = this.srNumber.split("; ");
      this.srArray.pop();
    } else {
      this.srArray.push(this.srNumber);
    }

    this.displayClass =
      this.srArray.length > 1 ? "flex flex-col gap-4" : "flex flex-col";
  },
};
</script>
